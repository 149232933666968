import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import swal from 'sweetalert2';
// import helper from '@/services/Helper';
import to from 'await-to-js';
import router from '@/router';
import Q from 'q';
//import FileDownload from 'js-file-download';

const config: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: true,
    baseURL: window.location.origin
};

interface ValidationErrorObject {
    [key: string]: string[];
}

export class ApiResult<T> {
    error: AxiosError | null;
    errorMessage: string;
    errorCode: number | null = null;
    data: T | null;
    hasValidationError: boolean;

    constructor(err: Error | null, res: AxiosResponse<T> | undefined) {
        this.error = err as AxiosError;
        if (typeof this.error?.response?.data === 'object' && this.error?.response?.data !== null) {
            this.errorMessage =
                this.error?.response?.data.detail ??
                this.error?.response?.data.message ??
                this.error?.response?.data.title ??
                this.error.message;
            this.errorCode = this.error?.response?.data.errorCode || null;
        } else this.errorMessage = this.error?.response?.data;
        this.data = res?.data ?? null;
        this.errorMessage = this.errorMessage || (this.data as any)?.error;
        this.errorCode = this.errorCode || (this.data as any)?.errorCode || null;
        this.hasValidationError = this.error?.response?.data?.errors ? true : false;
    }

    validationErrorsList() {
        const errors = this.error?.response?.data?.errors as ValidationErrorObject;
        if (!errors || Array.isArray(errors)) return null;
        return Object.keys(errors).map(key => ({ key, msg: errors[key].join !== undefined ? errors[key]?.join(',') : '' }));
    }
}

export abstract class BaseApi {
    private api: AxiosInstance;

    public constructor() {
        this.api = axios.create(config);
        this.api.interceptors.response.use(
            response => {
                return response;
            },
            (error: AxiosError) => {
                if (error.response && error.response.status === 456) {
                    return new Promise(function(resolve) {
                        swal.fire(error.response?.data.error, '', 'warning').then(() => {
                            window.location.reload();
                            resolve({ errorMessage: error.response?.data.error });
                        });
                    });
                }
                if (error.response && error.response.status === 401) {
                    console.log('Unauthorized request to API', error);
                    return new Promise(function(resolve) {
                        swal.fire('Unauthorized', '', 'warning').then(() => {
                            if (router.currentRoute.value.name != 'login') window.location.assign('');
                            resolve({ errorMessage: 'Unauthorized' });
                        });
                    });
                }
                if (error.response && error.response.status === 403) {
                    console.log('No permission request to API', error);
                    return { data: { error: /*i18n.t('general.permission.accessdenied')*/ 'No permission' } };
                }
                // if (error.response && error.response.status === 500) {
                //     swal.close()
                //     router.push('error');
                //     return;
                // }
                if (!error.response && error.isAxiosError && error.message) {
                    console.log('NetworkError', error.message);
                    return { data: { error: /*i18n.t('general.permission.accessdenied')*/ error.message } };
                }
                return Promise.reject(error);
            }
        );

        this.api.interceptors.request.use(config => {
            config.headers['ClientVersion'] = process.env.PACKAGE_VERSION;
            return config;
        });
    }

    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<ApiResult<T>> {
        const delay = Q.delay(400);
        const [axiosError, axiosResponse] = await to(this.api.get(url, config));
        await delay;
        return new ApiResult(axiosError, axiosResponse);
    }

    public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<ApiResult<T>> {
        const delay = Q.delay(400);
        const [axiosError, axiosResponse] = await to(this.api.delete(url, config));
        await delay;
        return new ApiResult(axiosError, axiosResponse);
    }

    public async post<T>(url: string, data?: string | object, config?: AxiosRequestConfig): Promise<ApiResult<T>> {
        const delay = Q.delay(400);
        const [axiosError, axiosResponse] = await to(this.api.post(url, data, config));
        await delay;
        return new ApiResult(axiosError, axiosResponse);
    }

    public async postFormData<T>(url: string, data: FormData, config?: AxiosRequestConfig): Promise<ApiResult<T>> {
        const delay = Q.delay(400);
        const [axiosError, axiosResponse] = await to(this.api.post(url, data, config));
        await delay;
        return new ApiResult(axiosError, axiosResponse);
    }

    public async put<T>(url: string, data?: string, config?: AxiosRequestConfig): Promise<ApiResult<T>> {
        const delay = Q.delay(400);
        const [axiosError, axiosResponse] = await to(this.api.put(url, data, config));
        await delay;
        return new ApiResult(axiosError, axiosResponse);
    }
}
