/* eslint-disable @typescript-eslint/no-explicit-any */
import { createWebHistory, createRouter } from 'vue-router';
import { generalStore } from '@/store';
import swal from 'sweetalert2';
import { Permissions } from '@/models/Interfaces';

const routes = [
    {
        path: '',
        redirect: { name: 'home' },
        component: () => import('@/AppLayout.vue'),
        children: [
            {
                path: '/:catchAll(.*)',
                name: 'error-not-found',
                component: () => import('@/components/ErrorNotFound.vue')
            },
            {
                path: 'error',
                name: 'error',
                component: () => import('@/components/Error.vue')
            },
            {
                path: '/',
                name: 'home',
                component: () => import('@/components/Home.vue'),
                meta: {
                    title: 'Bookit - Home',
                    height: 210
                }
            },
            {
                path: '/login',
                name: 'login',
                component: () => import('@/components/Login.vue'),
                meta: {
                    title: 'Bookit - Login',
                    AllowAnonymous: true,
                    class: 'login-backgroud-image'
                }
            },
            {
                path: '/catalog',
                name: 'catalog',
                component: () => import('@/components/Catalog.vue'),
                meta: {
                    title: 'Bookit - Activities',
                    permission: Permissions.ProductsViewSearch
                }
            },
            {
                path: '/catalog/filter',
                name: 'modal-catalog-filter',
                component: () => import('@/components/Modals/ProductsFilter.vue'),
                meta: {
                    title: 'Bookit - Catalog Filter',
                    class: 'popuplayout',
                    permission: Permissions.ProductsViewSearch
                },
                props: true
            },
            {
                path: '/tags',
                name: 'tags',
                component: () => import('@/components/Tags.vue'),
                meta: {
                    title: 'Bookit - Ref Data Tags'
                }
            },
            {
                path: '/create-product-step-one/:subStep/:productId?',
                name: 'createProductStepOne',
                component: () => import('@/components/CreateProductWizzard/StepOne.vue'),
                meta: {
                    title: 'Bookit - Create product step one',
                    height: 195,
                    permission: Permissions.CreateProducts
                },
                props(route: any) {
                    const props = { ...route.params };
                    props.productId = props.productId || '';
                    return props;
                }
            },
            {
                path: '/create-product-step-two/:subStep/:productId?',
                name: 'createProductStepTwo',
                component: () => import('@/components/CreateProductWizzard/StepTwo/StepTwo.vue'),
                meta: {
                    title: 'Bookit - Create product step two',
                    height: 195,
                    permission: Permissions.CreateProducts
                },
                props(route: any) {
                    const props = { ...route.params };
                    props.productId = props.productId || '';
                    return props;
                }
            },
            {
                path: '/create-product-step-three/:subStep/:productId?',
                name: 'createProductStepThree',
                component: () => import('@/components/CreateProductWizzard/StepThree.vue'),
                meta: {
                    title: 'Bookit - Create product step three',
                    height: 195,
                    permission: Permissions.CreateProducts
                },
                props(route: any) {
                    const props = { ...route.params };
                    props.productId = props.productId || '';
                    return props;
                }
            },
            {
                path: '/product/:id/:tab?',
                name: 'product',
                component: () => import('@/components/Product.vue'),
                meta: {
                    title: 'Bookit - Product details',
                    height: 210,
                    permission: Permissions.ProductsViewSearch
                },
                props: true
            },
            {
                path: '/product/:id/edit/:tab?',
                name: 'edit-product',
                component: () => import('@/components/EditProduct.vue'),
                meta: {
                    title: 'Bookit - Product details',
                    height: 200,
                    permission: Permissions.EditProducts
                },
                props: true
            },
            {
                path: '/order/:id',
                name: 'order',
                component: () => import('@/components/Order/Order.vue'),
                meta: {
                    title: 'Bookit - Order details',
                    height: 345,
                    permission: Permissions.ViewOrders
                },
                props: true
            },
            {
                path: '/order/:id/ticket',
                name: 'ticket',
                component: () => import('@/components/OrderTicket.vue'),
                meta: {
                    title: 'Bookit - Order Ticket',
                    AllowAnonymous: true
                },
                props: true
            },
            {
                path: '/orders',
                name: 'orders',
                component: () => import('@/components/Order/Orders.vue'),
                meta: {
                    title: 'Bookit - Orders',
                    permission: Permissions.ViewOrders
                }
            },
            {
                path: '/orders/new',
                name: 'neworder',
                component: () => import('@/components/Order/NewOrder.vue'),
                meta: {
                    title: 'Bookit - Create new order',
                    height: 160,
                    permission: Permissions.CreateOrders
                }
            },
            {
                path: '/orders/new/add-product',
                name: 'addorderproduct',
                component: () => import('@/components/Order/AddOrderProduct.vue'),
                meta: {
                    title: 'Bookit - Add order product',
                    height: 160
                }
            },
            {
                path: '/orders/new/add-product/slot',
                name: 'addorderproductslot',
                component: () => import('@/components/Order/AddOrderProduct_Slot.vue'),
                meta: {
                    title: 'Bookit - Add order product',
                    height: 170
                }
            },
            {
                path: '/orders/new/add-product/customer-type',
                name: 'addorderproductcustomertype',
                component: () => import('@/components/Order/AddOrderProduct_CustomerType.vue'),
                meta: {
                    title: 'Bookit - Add order product',
                    height: 205
                }
            },
            {
                path: '/order/:orderId/receipt/new',
                name: 'receiptNew',
                component: () => import('@/components/Accounting/ReceiptNew.vue'),
                meta: {
                    title: 'Bookit - Receipt',
                    height: 90,
                    permission: Permissions.DocumentViewSearch
                },
                props: true
            },
            {
                path: '/order/:orderId/receipt/:receiptId',
                name: 'receiptView',
                component: () => import('@/components/Accounting/ReceiptView.vue'),
                meta: {
                    title: 'Bookit - Receipt',
                    height: 90,
                    permission: Permissions.DocumentViewSearch
                },
                props: true
            },
            {
                path: '/order/:orderId/invoicereceipt/new',
                name: 'invoiceReceiptNew',
                component: () => import('@/components/Accounting/InvoiceReceiptNew.vue'),
                meta: {
                    title: 'Bookit - InvoiceReceipt',
                    height: 90,
                    permission: Permissions.DocumentViewSearch
                },
                props: true
            },
            {
                path: '/order/:orderId/invoicereceipt/:invoiceReceiptId',
                name: 'invoiceReceiptView',
                component: () => import('@/components/Accounting/InvoiceReceiptView.vue'),
                meta: {
                    title: 'Bookit - InvoiceReceipt',
                    height: 90,
                    permission: Permissions.DocumentViewSearch
                },
                props: true
            },
            {
                path: '/accounting/new-invoice/:orderId',
                name: 'newInvoice',
                component: () => import('@/components/Accounting/NewInvoice.vue'),
                meta: {
                    title: 'Bookit - New Invoice',
                    height: 90,
                    permission: Permissions.CreateInvoices
                },
                props: true
            },
            {
                path: '/accounting/new-perfoma-invoice/:orderId',
                name: 'newPerfomaInvoice',
                component: () => import('@/components/Accounting/NewPerfomaInvoice.vue'),
                meta: {
                    title: 'Bookit - New Perfoma Invoice',
                    height: 90,
                    permission: Permissions.CreatePerfomaInvoices
                },
                props: true
            },
            {
                path: '/accounting/new-credit-invoice/:orderId',
                name: 'newCreditInvoice',
                component: () => import('@/components/Accounting/NewCreditInvoice.vue'),
                meta: {
                    title: 'Bookit - New Credit Invoice',
                    height: 90,
                    permission: Permissions.CreateCreditInvoices
                },
                props: true
            },
            {
                path: '/order/:orderId/invoice/:invoiceId/view',
                name: 'invoiceView',
                component: () => import('@/components/Accounting/InvoiceView.vue'),
                meta: {
                    title: 'Bookit - Invoice',
                    height: 90,
                    permission: Permissions.DocumentViewSearch
                },
                props: true
            },
            {
                path: '/order/:orderId/invoice/:invoiceId/edit',
                name: 'invoiceEdit',
                component: () => import('@/components/Accounting/InvoiceEdit.vue'),
                meta: {
                    title: 'Bookit - Invoice',
                    height: 90,
                    permission: Permissions.DocumentViewSearch
                },
                props: true
            },
            {
                path: '/report/dashboard',
                name: 'dashboard',
                component: () => import('@/components/Report/Dashboard.vue'),
                meta: {
                    title: 'Dashboard',
                    height: 180,
                    backgroundColor: '#EAECF0',
                    permission: Permissions.ReportsView
                }
            },
            {
                path: '/calendar/v3/:view?/:date?',
                name: 'CalendarV3',
                component: () => import('@/components/Calendar/CalendarKendo.vue'),
                meta: {
                    title: 'Bookit - Calendar',
                    height: 95,
                    routerKey: '/calendar/v3/',
                    permission: Permissions.CalendarViewFilter
                },
                props: true
            },
            {
                path: '/order/:id/edit-date/:productId',
                name: 'modal-order-edit-productdate',
                component: () => import('@/components/Modals/ChangeOrderProductDate.vue'),
                meta: {
                    title: 'Bookit - Edit Order Product Date',
                    height: 180,
                    permission: Permissions.EditOrders
                },
                props: true
            },
            {
                path: '/order/new/edit-date',
                name: 'modal-neworder-edit-productdate',
                component: () => import('@/components/Modals/ChangeNewOrderProductDate.vue'),
                meta: {
                    title: 'Bookit - Edit Order Product Date',
                    height: 180,
                    permission: Permissions.EditOrders
                }
            },
            {
                path: '/documents',
                name: 'documents',
                component: () => import('@/components/Documents/Documents.vue'),
                meta: {
                    title: 'Bookit - Documents',
                    permission: Permissions.DocumentViewSearch
                }
            },
            //popupLayout
            {
                path: '/addQty/:dayId',
                name: 'addqty',
                component: () => import('@/components/Modals/UpdateCalendarDayQty.vue'),
                meta: {
                    title: 'Bookit - Product create',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/product/:id/translation/:type',
                name: 'edit-product-desc',
                component: () => import('@/components/Modals/EditProductNameDescriptions.vue'),
                meta: {
                    title: 'Bookit - Product edit',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/:entity/:id/edit/:type',
                name: 'EditTranslation',
                component: () => import('@/components/Modals/EditTranslation.vue'),
                meta: {
                    title: 'Bookit - Translation edit',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/product/:productId/translate/:id/:type/:infoType?',
                name: 'edit-product-translation',
                component: () => import('@/components/Modals/EditProductTranslationsTitleContent.vue'),
                meta: {
                    title: 'Bookit - Product edit',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/product/:id/edit/add-included-list',
                name: 'add-list-product',
                component: () => import('@/components/AddIncludedList.vue'),
                meta: {
                    title: 'Bookit - Product details',
                    class: 'popuplayout'
                }
            },
            {
                path: '/product/:id/edit/file/:type?',
                name: 'product-photo',
                component: () => import('@/components/EditProductFiles.vue'),
                meta: {
                    title: 'Bookit - Product details',
                    class: 'popuplayout'
                },
                props: true
            },
            // {
            //     path: '/product/:productId/edit/customer-type',
            //     name: 'product-customer-type',
            //     component: () => import('@/components/Modals/EditProductCustomerType.vue'),
            //     meta: {
            //         title: 'Bookit - Product details',
            //         class: 'popuplayout'
            //     }
            // },
            {
                path: '/product/:id/edit/price-list/:priceId?/:action?',
                name: 'product-price-list',
                component: () => import('@/components/Modals/ProductPriceList.vue'),
                meta: {
                    title: 'Bookit - Product details',
                    class: 'popuplayout'
                }
            },
            {
                path: '/modal-tags/:tagId/:productId/:type?',
                name: 'modal-tags',
                component: () => import('@/components/Modals/TagsModal.vue'),
                meta: {
                    title: 'Bookit - Tags',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/modal-regions',
                name: 'modal-regions',
                component: () => import('@/components/Modals/RegionsModal.vue'),
                meta: {
                    title: 'Bookit - Regions',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/orders/filter',
                name: 'modal-orders-filter',
                component: () => import('@/components/Modals/OrdersFilter.vue'),
                meta: {
                    title: 'Bookit - Orders Filter',
                    class: 'popuplayout',
                    permission: Permissions.ViewOrders
                },
                props: true
            },
            {
                path: '/order/:id/edit',
                name: 'modal-order-edit',
                component: () => import('@/components/Modals/EditOrder.vue'),
                meta: {
                    title: 'Bookit - Edit Order',
                    class: 'popuplayout',
                    permission: Permissions.EditOrders
                }
            },
            {
                path: '/order/:id/remarks',
                name: 'modal-order-edit-remarks',
                component: () => import('@/components/Modals/OrderRemarksEdit.vue'),
                meta: {
                    title: 'Bookit - Edit Order Remarks',
                    class: 'popuplayout'
                }
            },
            {
                path: '/documents/filter',
                name: 'filterdocuments',
                component: () => import('@/components/Documents/FilterDocuments.vue'),
                meta: {
                    title: 'Bookit - FilterDocuments',
                    class: 'popuplayout',
                    permission: Permissions.DocumentViewSearch
                }
            },
            {
                path: '/document/:documentId',
                name: 'sharedocument',
                component: () => import('@/components/Documents/ShareDocument.vue'),
                meta: {
                    title: 'Bookit - ShareDocument',
                    class: 'popuplayout',
                    permission: Permissions.DocumentShare
                },
                props: true
            },
            {
                path: '/location-map/:productId',
                name: 'location-map',
                component: () => import('@/components/Modals/LocationMap.vue'),
                meta: {
                    title: 'Bookit - Location map',
                    height: 90
                },
                props: true
            },
            {
                path: '/location-map/provider',
                name: 'location-map-provider',
                component: () => import('@/components/Modals/LocationMapProvider.vue'),
                meta: {
                    title: 'Bookit - Provider Location map',
                    height: 90,
                    permission: Permissions.EditProviderSettings
                }
            },
            {
                path: '/registration',
                name: 'registration',
                component: () => import('@/components/Registration.vue'),
                meta: {
                    title: 'Bookit - Registration',
                    class: 'login-backgroud-image registration',
                    AllowAnonymous: true
                }
            },
            {
                path: '/registration-details/:token',
                name: 'registration-details',
                component: () => import('@/components/RegistrationDetails.vue'),
                meta: {
                    title: 'Bookit - Registration Details',
                    class: 'login-backgroud-image registration',
                    AllowAnonymous: true
                },
                props: true
            },
            {
                path: '/reset-password/:token',
                name: 'reset-password',
                component: () => import('@/components/ResetPassword.vue'),
                meta: {
                    title: 'Bookit - Reset password',
                    class: 'login-backgroud-image registration',
                    AllowAnonymous: true
                },
                props: true
            },
            {
                path: '/provider/settings',
                name: 'provider-settings',
                component: () => import('@/components/ProviderSettings/ProviderSettings.vue'),
                meta: {
                    title: 'Bookit - Provider Settings',
                    height: 310,
                    permission: Permissions.ViewProviderSettings
                }
            },
            {
                path: '/provider/settings/edit',
                name: 'provider-settings-edit',
                component: () => import('@/components/ProviderSettings/ProviderSettingsEdit.vue'),
                meta: {
                    title: 'Bookit - Provider Settings Edit',
                    height: 310,
                    permission: Permissions.EditProviderSettings
                }
            },
            {
                path: '/provider/user/:id',
                name: 'EditProviderUser',
                component: () => import('@/components/ProviderSettings/EditProviderUser.vue'),
                meta: {
                    title: 'Bookit - Provider User Edit',
                    class: 'popuplayout',
                    permission: Permissions.EditProviderSettings
                },
                props: true
            },
            {
                path: '/settings/currency/:id',
                name: 'ProviderCurrencyRate',
                component: () => import('@/components/Modals/ProviderCurrencyRate.vue'),
                meta: {
                    title: 'Bookit - Currency',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/settings/currency/new',
                name: 'NewProviderCurrencyRate',
                component: () => import('@/components/Modals/NewProviderCurrencyRate.vue'),
                meta: {
                    title: 'Bookit - Currency',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/provider/profile/:id',
                name: 'EditProviderProfile',
                component: () => import('@/components/ProviderSettings/EditProviderProfile.vue'),
                meta: {
                    title: 'Bookit - Edit Profile',
                    class: 'popuplayout',
                    permission: Permissions.EditProviderSettings
                },
                props: true
            },
            {
                path: '/businessClients',
                name: 'businessClients',
                component: () => import('@/components/BusinessClients/BusinessClients.vue'),
                meta: {
                    title: 'Bookit - Business Clients',
                    height: 180
                }
            },
            {
                path: '/businessClients/filter',
                name: 'modal-business-clients-filter',
                component: () => import('@/components/BusinessClients/BusinessClientsFilter.vue'),
                meta: {
                    title: 'Bookit - Business Clients Filter',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/businessClient/new',
                name: 'businessClientNew',
                component: () => import('@/components/BusinessClients/BusinessClientNew.vue'),
                meta: {
                    title: 'Bookit - Business Clients',
                    height: 150
                }
            },
            {
                path: '/businessClient/:id/edit/:page',
                name: 'businessClientEdit',
                component: () => import('@/components/BusinessClients/BusinessClientEdit.vue'),
                meta: {
                    title: 'Bookit - Business Clients',
                    height: 170
                },
                props: true
            },
            {
                path: '/businessClient/:id/view/:page',
                name: 'businessClientView',
                component: () => import('@/components/BusinessClients/BusinessClientView.vue'),
                meta: {
                    title: 'Bookit - Business Clients',
                    height: 170
                },
                props: true
            },
            {
                path: '/smshistory',
                name: 'smshistory',
                component: () => import('@/components/SmsHistory.vue'),
                meta: {
                    title: 'Bookit - Sms History'
                }
            },
            {
                path: '/chat-quotes/:messageId?',
                name: 'chatQuotes',
                component: () => import('@/components/ChatQuotes.vue'),
                meta: {
                    title: 'Bookit - Index Chat and Quotes',
                    height: 180
                },
                props: true
            },
            {
                path: '/index/statistics',
                name: 'indexStatistics',
                component: () => import('@/components/Report/IndexStatistics.vue'),
                meta: {
                    title: 'Bookit - Index Statistics',
                    height: 180,
                    backgroundColor: '#EAECF0'
                }
            },
            {
                path: '/agent-details/:id',
                name: 'agent-details',
                component: () => import('@/components/AgentDetails.vue'),
                meta: {
                    title: 'Bookit - Agent Details',
                    class: 'popuplayout'
                }
            },
            {
                path: '/provider/coupons',
                name: 'coupons',
                component: () => import('@/components/Coupons/ProviderCoupons.vue'),
                meta: {
                    title: 'Bookit - Coupons',
                    height: 210
                }
            },
            {
                path: '/provider/payments-account-transactions',
                name: 'payments-account-transactions',
                component: () => import('@/components/PaymentsAccountTransactions.vue'),
                meta: {
                    title: 'Bookit - Payments Account Transactions'
                }
            },
            {
                path: '/coupons/filter',
                name: 'modal-coupon-filter',
                component: () => import('@/components/Modals/CouponsFilter.vue'),
                meta: {
                    title: 'Bookit - Coupon Filter',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/coupon/new',
                name: 'coupon-new',
                component: () => import('@/components/Coupons/CouponDetails.vue'),
                meta: {
                    title: 'Bookit - New Coupon',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/coupon/edit/:couponId',
                name: 'coupon-edit',
                component: () => import('@/components/Coupons/CouponDetails.vue'),
                meta: {
                    title: 'Bookit - Edit Coupon',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/businessClient/:clientId/filter',
                name: 'businessAccountingFilter',
                component: () => import('@/components/BusinessClients/BusinessClientAccountingFilter.vue'),
                meta: {
                    title: 'Bookit - Business Accounting Filter',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/businessClientOrderProducts/:clientId/filter',
                name: 'businessOrderProductsFilter',
                component: () => import('@/components/BusinessClients/BusinessClientOrderProductsFilter.vue'),
                meta: {
                    title: 'Bookit - Business Order Products Filter',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/businessClient/:clientId/newInvoice',
                name: 'businessInvoiceNew',
                component: () => import('@/components/BusinessClients/BusinessDocuments/BusinessInvoiceNew.vue'),
                meta: {
                    title: 'Bookit - Business Invoice',
                    height: 170
                },
                props: true
            },
            {
                path: '/businessClient/:clientId/newPerfomaInvoice',
                name: 'businessPerfomaInvoiceNew',
                component: () => import('@/components/BusinessClients/BusinessDocuments/BusinessPerfomaInvoice.vue'),
                meta: {
                    title: 'Bookit - Perfoma Invoice',
                    height: 170
                },
                props: true
            },
            {
                path: '/businessClient/:clientId/newCreditInvoice',
                name: 'businessCreditInvoiceNew',
                component: () => import('@/components/BusinessClients/BusinessDocuments/BusinessCreditInvoiceNew.vue'),
                meta: {
                    title: 'Bookit - Business Credit Invoice',
                    height: 170
                },
                props: true
            },
            {
                path: '/businessClient/:clientId/invoice/:invoiceId',
                name: 'businessInvoiceView',
                component: () => import('@/components/BusinessClients/BusinessDocuments/BusinessInvoiceView.vue'),
                meta: {
                    title: 'Bookit - Business Invoice',
                    height: 170
                },
                props: true
            },
            {
                path: '/businessClient/:clientId/invoice/:invoiceId/edit',
                name: 'businessInvoiceEdit',
                component: () => import('@/components/BusinessClients/BusinessDocuments/BusinessInvoiceEdit.vue'),
                meta: {
                    title: 'Bookit - Business Invoice',
                    height: 170
                },
                props: true
            },
            {
                path: '/businessClient/:clientId/newReceipt',
                name: 'businessReceiptNew',
                component: () => import('@/components/BusinessClients/BusinessDocuments/BusinessReceiptNew.vue'),
                meta: {
                    title: 'Bookit - Business Receipt',
                    height: 170
                },
                props: true
            },
            {
                path: '/businessClient/:clientId/receipt/:receiptId',
                name: 'businessReceiptView',
                component: () => import('@/components/BusinessClients/BusinessDocuments/BusinessReceiptView.vue'),
                meta: {
                    title: 'Bookit - Business Receipt',
                    height: 170
                },
                props: true
            },
            {
                path: '/businessClient/:clientId/receipt/:receiptId/edit',
                name: 'businessReceiptEdit',
                component: () => import('@/components/BusinessClients/BusinessDocuments/BusinessReceiptEdit.vue'),
                meta: {
                    title: 'Bookit - Business Receipt',
                    height: 170
                },
                props: true
            },
            {
                path: 'provider/accounting/:tab',
                name: 'AccountingInfoView',
                component: () => import('@/components/Accounting/AccountingInfo/AccountingInfoView.vue'),
                meta: {
                    title: 'Bookit - Accounting',
                    height: 170
                },
                props: true
            },
            {
                path: 'provider/accounting-edit/:tab',
                name: 'AccountingInfoEditView',
                component: () => import('@/components/Accounting/AccountingInfo/AccountingInfoEditView.vue'),
                meta: {
                    title: 'Bookit - Accounting',
                    height: 170
                },
                props: true
            },
            {
                path: 'export',
                name: 'Export',
                component: () => import('@/components/Export/Export.vue'),
                meta: {
                    title: 'Bookit - Export',
                    height: 170
                }
            },
            {
                path: 'employee/no-slots',
                name: 'SlotsWithNoEmployee',
                component: () => import('@/components/SlotsWithNoEmployee.vue'),
                meta: {
                    title: 'Bookit - Slots With No Employee',
                    height: 170
                }
            },
            {
                path: 'employees',
                name: 'EmployeesTable',
                component: () => import('@/components/Employee/EmployeesTable.vue'),
                meta: {
                    title: 'Bookit - Employees',
                    height: 170
                }
            },
            {
                path: '/employee/:id',
                name: 'EmployeeView',
                component: () => import('@/components/Employee/EmployeeView.vue'),
                meta: {
                    title: 'Bookit - Employee',
                    height: 170
                },
                props: true
            },
            {
                path: '/edit-employee/:id',
                name: 'EditEmployee',
                component: () => import('@/components/Employee/EditEmployee.vue'),
                meta: {
                    title: 'Bookit - Edit Employee',
                    height: 170
                },
                props: true
            },
            {
                path: '/employees/filter',
                name: 'modal-employees-filter',
                component: () => import('@/components/Modals/EmployeesFilter.vue'),
                meta: {
                    title: 'Bookit - Employees Filter',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/employees/filter/tags',
                name: 'modal-employees-filter-tags',
                component: () => import('@/components/Modals/EmployeesFilterTags.vue'),
                meta: {
                    title: 'Bookit - Filter Tags',
                    class: 'popuplayout'
                },
                props: true
            },
            {
                path: '/message-management/:tab',
                name: 'message-management',
                component: () => import('@/components/SmsEmailMessages/MessageManagement.vue'),
                meta: {
                    title: 'Bookit - Message Management'
                },
                props: true
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

function gotPermission(permId: number): boolean {
    if (generalStore.getters.GetPermissions == null || generalStore.getters.GetPermissions.length == 0) {
        return false;
    }

    return generalStore.getters.GetPermissions.includes(permId);
}

router.beforeEach((to, from, next) => {
    const isAuthenticated = generalStore?.state?.isAuthenticated;

    document.title = to.meta.title || 'Bookit';
    if (!to.meta?.AllowAnonymous && to.name !== 'login' && !isAuthenticated) {
        next({ name: 'login' });
    } else if (isAuthenticated && to.meta?.AllowAnonymous) {
        if (to.name == 'ticket') {
            next();
        } else {
            next({ name: 'home' });
        }
    } else if (to.meta.permission) {
        if (!gotPermission(to.meta.permission)) {
            swal.fire({
                icon: 'error',
                text: 'No permission'
            });
            if (!from.name) {
                next({ name: 'home' });
            }
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
